@import 'styles/variables';

.mainContainer {
    background-color: #D8D8D8;
    height: 100vh;
    width: 100vw;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 64px;
    // can move electron window
    -webkit-app-region: drag;
}

.formContainer {
    //cancel drag
    -webkit-app-region: no-drag;
    
    width: 100%;
    padding: 40px 64px;
    // border: 1px solid #ccc;
    border-radius: 16px;
    background: var(--fill-group, #FAFAFA);
    max-width: 490px;

    /* Modal */
    box-shadow: 0px 30px 65px -10px rgba(0, 0, 0, 0.38), 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
}