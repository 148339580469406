@import 'styles/mixins';

.logoDropShadow {
    filter: drop-shadow(0px 2.1751890182495117px 4.350378036499023px rgba(0, 0, 0, 0.30)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
}

.logoContainer {
    display: flex;
    align-items: center;
    gap: 13.95px;

    color: #222;
    font-size: 30.225px;
    font-style: normal;
    font-weight: 590;
    line-height: normal;
}

.discoLogo {
    svg {
        width: 47.852px;
        height: 47.854px;
    }
    composes: logoDropShadow;
}

.signupFormHeaderBase {
    display: flex;
    flex-direction: column;
    color: var(--text-primary, rgba(0, 0, 0, 0.85));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 46.8px */
    white-space: nowrap;
    gap: 10px;

    @include mobile {
        font-size: 24px;
    }
}

.googleButton {
    transition: all 0.2s;
    border-radius: 12px;
    border: 0.75px solid #F0F0F0;
    background: radial-gradient(151.68% 100% at 50% 0%, #FFF 0%, #FCFCFC 100%);
    box-shadow: 0px 1.5px 6px 0px rgba(0, 0, 0, 0.12);

    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    width: 100%;

    &:hover {
        border: 0.75px solid #d6d6d6;
        background: radial-gradient(151.68% 100% at 50% 0%, #FFF 0%, #FCFCFC 100%);
        // box-shadow: 0px 3.5px 6px 0px rgba(0, 0, 0, 0.12);
    }

    &:active {
        border: 0.75px solid #d6d6d6;
        background: radial-gradient(151.68% 100% at 50% 0%, #f7f7f7 0%, #FCFCFC 100%);
    }

    &:focus {
        border: 0.75px solid #d6d6d6;
    }
}

.input {
    transition: all 0.2s ease-in-out;

    display: flex;
    padding: 12px 18px;
    align-items: center;
    gap: 1px;
    align-self: stretch;
    height: initial;

    border-radius: 12px;
    border: 1px solid rgba(219, 219, 219, 0.5);
    background: #F0F0F0;

    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 115.789% */

    &:hover:not(:focus) {
        border: 1px solid rgba(219, 219, 219, 0.5);
        background: #f2f2f2;
    }
    &:focus {
        border: 1.5px solid #00a0f7;
        background: #f5f5f5;
    }
    &::placeholder {
        color: #8b979c;
        font-size: 17px;
    }
}

.codeInput {
    text-align: center;
}

.signupButton {
    transition: all 0.2s;

    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border-radius: 12px;
    border: 0.75px solid #F0F0F0 !important;
    background: radial-gradient(151.68% 100% at 50% 0%, #233E66 0%, #000 100%), radial-gradient(151.68% 100% at 50% 0%, #FFF 0%, #FCFCFC 100%);
    box-shadow: 0px 1.5px 6px 0px rgba(0, 0, 0, 0.12);
    color: white;

    &:hover {
        background: radial-gradient(151.68% 100% at 50% 0%, hsl(216, 49%, 34%) 0%, #000 100%),
            radial-gradient(151.68% 100% at 50% 0%, #fff 0%, #fcfcfc 100%);
        border: 0.75px solid #f0f0f0;
    }
}

.signInLink, .or {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 19.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 123.077% */
    padding-top: 10px;

    span {
        color: var(--color-blue, #007aff);
    }

    @include mobile {
        font-size: 16px;
        line-height: 20px;
    }
}
